import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'countries.create',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        status: false,
        country_code: null,
        translations: [],
      },
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    payload: {
      country_code: {
        required,
        minLength: minLength(1),
      },
      translations: {
        $each: {
          title: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20)
          }
        }
      }
    }
  },
  created() {
    this._applyData();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isCountryCreating: 'countries/isCountryCreating',
    }),
    ...mapGetters([
      'languages',
    ]),
    getUpperCaseCode() {
      return this.payload.country_code ? this.payload.country_code.toUpperCase() : null
    },
    countryCodeErrors() {
      let error = [];
      if (!this.$v.payload.country_code.$dirty) {
        return error;
      }
      if (!this.$v.payload.country_code.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.country_code.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (this.validationErrors.country_code) {
        this.validationErrors.country_code.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      createCountry: 'countries/COUNTRIES_REQUEST_CREATE',
    }),
    _applyData() {
      this.payload.translations = this.languages.map(langItem => {
        return {
          locale: langItem.translationKey,
          title: '',
        }
      });
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.payload.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.payload.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.payload.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      return error;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload = {
          status: this.payload.status,
          country_code: this.payload.country_code,
        }
        this.payload.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title
          }
        });

        this.createCountry(payload).then(() => {
          this.$toasted.success(this.$t('dataUpdated'));
          this.$router.push({name: 'countries.list'}).catch(()=> {console.log()})
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
